<app-header (menuToggled)="toggle()" class="sticky-top"></app-header>
<div class="h-100">
  <mat-drawer-container>
    <mat-drawer mode="side" [opened]="opened" class="sidebar_bg"> </mat-drawer>
    <mat-drawer-content (click)="hideSidebar()">
      <div class="header-heading ps-4">
        <h2 class="ms-2">Add User</h2>
      </div>
      <section class="p-4">
        <h2>Create Admin User</h2>
        <form
          [formGroup]="createUserForm"
          (ngSubmit)="onSubmit()"
          class="add_form"
        >
          <div class="row">
            <div class="col-lg-6">
              <label for="">First Name</label>
              <mat-form-field>
                <mat-label>First Name</mat-label>
                <input matInput formControlName="firstName" />
                <mat-icon matSuffix>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M11.5 8C12.3284 8 13 8.67157 13 9.5V10C13 11.9714 11.1405 14 8 14C4.85951 14 3 11.9714 3 10V9.5C3 8.67157 3.67157 8 4.5 8H11.5ZM8 1.5C9.51878 1.5 10.75 2.73122 10.75 4.25C10.75 5.76878 9.51878 7 8 7C6.48122 7 5.25 5.76878 5.25 4.25C5.25 2.73122 6.48122 1.5 8 1.5Z"
                      fill="#98A2B3"
                    />
                  </svg>
                </mat-icon>
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <label for="">Last Name</label>
              <mat-form-field>
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="lastName" />
                <mat-icon matSuffix>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M11.5 8C12.3284 8 13 8.67157 13 9.5V10C13 11.9714 11.1405 14 8 14C4.85951 14 3 11.9714 3 10V9.5C3 8.67157 3.67157 8 4.5 8H11.5ZM8 1.5C9.51878 1.5 10.75 2.73122 10.75 4.25C10.75 5.76878 9.51878 7 8 7C6.48122 7 5.25 5.76878 5.25 4.25C5.25 2.73122 6.48122 1.5 8 1.5Z"
                      fill="#98A2B3"
                    />
                  </svg>
                </mat-icon>
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <label for="">Email</label>
              <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" />
                <mat-icon matSuffix>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M11.5 8C12.3284 8 13 8.67157 13 9.5V10C13 11.9714 11.1405 14 8 14C4.85951 14 3 11.9714 3 10V9.5C3 8.67157 3.67157 8 4.5 8H11.5ZM8 1.5C9.51878 1.5 10.75 2.73122 10.75 4.25C10.75 5.76878 9.51878 7 8 7C6.48122 7 5.25 5.76878 5.25 4.25C5.25 2.73122 6.48122 1.5 8 1.5Z"
                      fill="#98A2B3"
                    />
                  </svg>
                </mat-icon>
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <label for="">Select Organization</label>
              <mat-form-field class="small-field">
                <mat-label>Select Organization</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="Search..."
                  (input)="filterOptions($event)"
                />
                <mat-select
                  formControlName="organization"
                  [(ngModel)]="selectedService"
                >
                  <mat-option
                    *ngFor="let request of service"
                    [value]="request.name"
                  >
                    {{ request.name }}
                  </mat-option>
                </mat-select>
                <mat-icon matSuffix>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M16.8322 7.38355C17.2416 7.79294 17.6284 8.26104 17.9652 8.74899C18.122 8.97626 18.065 9.28765 17.8377 9.4445C17.6104 9.60136 17.299 9.54428 17.1422 9.31701C16.839 8.87776 16.4907 8.45628 16.1251 8.09066C12.762 4.72759 7.30939 4.72759 3.94632 8.09066C3.59808 8.4389 3.25349 8.86025 2.93931 9.31314C2.78191 9.54003 2.47038 9.59636 2.24349 9.43896C2.0166 9.28155 1.96026 8.97002 2.11767 8.74313C2.46413 8.24371 2.84605 7.77671 3.23921 7.38355C6.9928 3.62996 13.0786 3.62996 16.8322 7.38355ZM14.5965 9.35699C15.0688 9.82932 15.4783 10.403 15.7903 11.013C15.916 11.2589 15.8187 11.5601 15.5728 11.6859C15.327 11.8116 15.0257 11.7143 14.9 11.4684C14.6342 10.9488 14.2856 10.4603 13.8894 10.0641C11.761 7.93576 8.31033 7.93576 6.18199 10.0641C5.76862 10.4775 5.43266 10.9425 5.17405 11.4538C5.04941 11.7002 4.74862 11.7989 4.5022 11.6742C4.25579 11.5496 4.15707 11.2488 4.28171 11.0024C4.58795 10.397 4.98633 9.84555 5.47489 9.35699C7.99375 6.83813 12.0776 6.83813 14.5965 9.35699ZM12.8875 11.8541C13.2418 12.2084 13.5337 12.6442 13.7369 13.1079C13.8477 13.3608 13.7325 13.6557 13.4796 13.7665C13.2267 13.8773 12.9318 13.7621 12.821 13.5092C12.6666 13.157 12.4444 12.8252 12.1804 12.5613C10.9959 11.3768 9.07546 11.3768 7.89097 12.5613C7.62827 12.824 7.41546 13.1432 7.25991 13.4987C7.14921 13.7517 6.85439 13.867 6.60141 13.7563C6.34842 13.6456 6.23307 13.3508 6.34377 13.0978C6.5479 12.6313 6.83023 12.2078 7.18386 11.8541C8.75888 10.2791 11.3125 10.2791 12.8875 11.8541ZM10.9628 13.7868C11.4699 14.2938 11.4699 15.116 10.9628 15.623C10.4558 16.1301 9.63367 16.1301 9.1266 15.623C8.61954 15.116 8.61954 14.2938 9.1266 13.7868C9.63367 13.2797 10.4558 13.2797 10.9628 13.7868Z"
                      fill="#98A2B3"
                    />
                  </svg>
                </mat-icon>
              </mat-form-field>
            </div>
          </div>
          <div class="text-end border-top pt-3">
            <button
              type="reset"
              mat-stroked-button
              class="me-3"
              type="button"
              (click)="goBack()"
            >
              Cancel
            </button>
            <button
              type="submit"
              mat-raised-button
              color="primary"
              [disabled]="createUserForm.invalid"
            >
              Submit
            </button>
          </div>
        </form>
      </section>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
