import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MasterComponent } from './master.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoaderModule } from '@vertel/loader';
import { ToasterModule } from '@vertel/toaster';
import { SharedModule } from 'src/app/shared/shared.module';
import { ServicesCardComponent } from 'src/app/standalone/services-card/services-card.component';
import { ToggleGroupComponent } from 'src/app/standalone/toggle-group/toggle-group.component';
import { RouterModule, Routes } from '@angular/router';
import { UsersService } from '~pages/users/users.service';
import { AdduserComponent } from './adduser/adduser.component';

const routes: Routes = [
  {
    path: '',
    component: MasterComponent,
    data: { queryParams: { d: 'true' } },
  },
  {
    path: 'add-user',
    component: AdduserComponent,
  },
];
@NgModule({
  declarations: [MasterComponent, AdduserComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatCardModule,
    ServicesCardComponent,
    MatButtonModule,
    MatDialogModule,
    MatTabsModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatDividerModule,
    MatFormFieldModule,
    ToggleGroupComponent,
    HttpClientModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    MatListModule,
    SharedModule,
    MatIconModule,
    ReactiveFormsModule,
    LoaderModule,
    ToasterModule,
    MatTooltipModule,
    MatSelectModule,
  ],
  providers: [UsersService],
})
export class MasterModule {}
