import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { GlobalService } from '~services/global/global.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private globalService: GlobalService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const accessToken = this.globalService.accessToken$.getValue();
    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    // const authReq = request.clone({
    //   headers: request.headers.set('Authorization', accessToken)
    // });

    // Clone the request and set the new header in one step.
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const authReq = request.clone({
      setHeaders: { Authorization: 'Bearer ' + accessToken },
    });

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}

export const AUTH_INTERCEPTOR = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};
