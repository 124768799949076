import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Alert } from '@vertel/alert';
import { Loader } from '@vertel/loader';
import { UsersService } from '~pages/users/users.service';
import { PAGE_SIZE_OPTIONS } from '~services/global/global.service';
import { stringify } from 'qs';
import { MasterService } from './master.service';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class MasterComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('outerSort', { static: true }) sort!: MatSort;
  data!: MatTableDataSource<any>;
  dataSource!: MatTableDataSource<any>;
  noDataFound = false;
  dataFound = false;
  length = 0;
  pageSize = PAGE_SIZE_OPTIONS[0];
  pageIndex = 0;
  pageSizeOptions = PAGE_SIZE_OPTIONS;
  columnsToDisplay = [
    'User Name',
    'Email',
    'User Type',
    'Organization',
    'Account ID',
    'Company ID',
    'Actiavte/Deactivate',
  ];
  constructor(
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private userService: UsersService,
    private loader: Loader,
    private alert: Alert,
    private masterService: MasterService,
  ) {}

  ngAfterViewInit() {
    if (!this.data) {
      return;
    }
    this.data.paginator = this.paginator;
  }

  ngOnInit() {
    this.getDetails();
  }

  getDetails() {
    this.loader.start();
    this.userService.getUsers().subscribe(
      (res: any) => {
        this.data = new MatTableDataSource(res.data);
        this.data.paginator = this.paginator;
        this.length = res.pagination.total;
        this.data.sort = this.sort;
        this.data.paginator.firstPage();
        if (this.length > 0) {
          this.dataFound = true;
          this.noDataFound = false;
        } else {
          this.dataFound = false;
          this.noDataFound = true;
        }
        this.loader.stop();
      },
      (error) => {
        this.loader.stop();
        this.alert.present(error.error.message);
      },
    );
  }

  opened = true;
  toggle(): void {
    this.opened = !this.opened;
  }

  hideSidebar() {
    this.breakpointObserver
      .observe(['(max-width: 767px)'])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.opened = false;
        }
      });
  }

  toggleMobile(): void {
    this.breakpointObserver
      .observe(['(max-width: 767px)'])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.opened = !this.opened;
        }
      });
  }

  handlePageEvent(event: PageEvent) {
    this.loader.start();
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    const query = stringify(
      {
        page: this.pageIndex,
        limit: this.pageSize,
      },
      {
        encodeValuesOnly: true,
        arrayFormat: 'comma',
      },
    );
    this.userService.getUsers(query).subscribe(
      (res: any) => {
        this.data = new MatTableDataSource(res.data);
        this.length = res.pagination.total;
        this.data.sort = this.sort;
        if (this.length > 0) {
          this.dataFound = true;
          this.noDataFound = false;
        } else {
          this.dataFound = false;
          this.noDataFound = true;
        }
        this.loader.stop();
      },
      (error) => {
        this.loader.stop();
        this.alert.present(error.error.message);
      },
    );
  }

  onActivate(status: string, id: string, firstName: string, lastName: string) {
    const payload = {
      status,
      firstName,
      lastName,
    };
    this.masterService.uesrActivateorDeactivate(payload, id).subscribe(
      (res: any) => {
        this.alert.present(res.message);
        this.getDetails();
      },
      (error) => {
        this.alert.present(error.error.message);
      },
    );
  }
}
