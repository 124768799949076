<app-header (menuToggled)="toggle()" class="sticky-top"></app-header>
<div class="h-100">
  <mat-drawer-container>
    <mat-drawer mode="side" [opened]="opened" class="sidebar_bg"> </mat-drawer>
    <mat-drawer-content (click)="hideSidebar()">
      <div class="header-heading ps-4">
        <h2 class="ms-2">Master List</h2>
      </div>
      <section class="p-4">
        <div class="container pt-2 mt-4">
          <div class="row">
            <div class="col-lg-4"></div>
            <div class="col-lg-8 d-flex justify-content-end">
              <!-- <div class="search_box">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              d="M5.5 0C8.53757 0 11 2.46243 11 5.5C11 6.83879 10.5217 8.06586 9.72656 9.01962L13.8536 13.1464C14.0488 13.3417 14.0488 13.6583 13.8536 13.8536C13.68 14.0271 13.4106 14.0464 13.2157 13.9114L13.1464 13.8536L9.01962 9.72656C8.06586 10.5217 6.83879 11 5.5 11C2.46243 11 0 8.53757 0 5.5C0 2.46243 2.46243 0 5.5 0ZM5.5 1C3.01472 1 1 3.01472 1 5.5C1 7.98528 3.01472 10 5.5 10C7.98528 10 10 7.98528 10 5.5C10 3.01472 7.98528 1 5.5 1Z"
                              fill="#667085"
                            />
                          </svg>
                          <input
                            matInput
                            
                            placeholder="Search"
                          />
                        </div> -->
              <a
                mat-raised-button
                color="primary"
                class="ms-2"
                [routerLink]="['/master/add-user']"
                [queryParams]="{ d: 'true' }"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8.5 2.75C8.5 2.33579 8.16421 2 7.75 2C7.33579 2 7 2.33579 7 2.75V7H2.75C2.33579 7 2 7.33579 2 7.75C2 8.16421 2.33579 8.5 2.75 8.5H7V12.75C7 13.1642 7.33579 13.5 7.75 13.5C8.16421 13.5 8.5 13.1642 8.5 12.75V8.5H12.75C13.1642 8.5 13.5 8.16421 13.5 7.75C13.5 7.33579 13.1642 7 12.75 7H8.5V2.75Z"
                    fill="white"
                  />
                </svg>
                Add User
              </a>
            </div>
          </div>
        </div>

        <div class="table-section mt-4 px-3">
          <table mat-table matSort [dataSource]="data" *ngIf="dataFound">
            <ng-container matColumnDef="User Name">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                User Name
              </th>
              <td mat-cell *matCellDef="let element" class="text-capitalize">
                {{ element.firstName + " " + element.lastName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Email">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Email</th>
              <td mat-cell *matCellDef="let element" class="text-capitalize">
                {{ element.email }}
              </td>
            </ng-container>
            <ng-container matColumnDef="User Type">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                User Type
              </th>
              <td mat-cell *matCellDef="let element" class="text-capitalize">
                {{ element.userTypeId }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Organization">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Organization
              </th>
              <td mat-cell *matCellDef="let element" class="text-capitalize">
                {{ element.organizations.name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Account ID">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Account ID
              </th>
              <td mat-cell *matCellDef="let element" class="text-capitalize">
                {{ element.organizations.accountId }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Company ID">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Company ID
              </th>
              <td mat-cell *matCellDef="let element" class="text-capitalize">
                {{ element.organizations.companyId }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Actiavte/Deactivate">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Actiavte/Deactivate
              </th>
              <td mat-cell *matCellDef="let element" class="text-capitalize">
                <button
                  *ngIf="element.status === 'active'"
                  class="view_btn_table d-flex align-items-center text-capitalize status-btn"
                  (click)="
                    onActivate(
                      'inactive',
                      element._id,
                      element.firstName,
                      element.lastName
                    )
                  "
                >
                  Deactivate
                </button>
                <button
                  *ngIf="element.status === 'inactive'"
                  class="active_btn_table d-flex align-items-center text-capitalize status-btn"
                  (click)="
                    onActivate(
                      'active',
                      element._id,
                      element.firstName,
                      element.lastName
                    )
                  "
                >
                  Activate
                </button>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="columnsToDisplay; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
          </table>
          <div *ngIf="noDataFound">
            <div class="p-4 col-lg-4 mx-auto mt-5">
              <div class="text-center">
                <span class="h5 mt-1 mb-2 d-block">No Data found</span>
              </div>
            </div>
          </div>
          <mat-paginator
            [ngClass]="{ 'd-none': length === 0 }"
            (page)="handlePageEvent($event)"
            [length]="length"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
          ></mat-paginator>
        </div>
      </section>
      <div class="tertiary-bg py-3 mt-5">
        <ul class="d-flex justify-content-center gap-3">
          <li>Contact us -</li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M18.5001 7.373V14.5C18.5001 15.8807 17.3808 17 16.0001 17H5.00008C3.61937 17 2.5 15.8807 2.5 14.5V7.373L10.2466 11.931C10.403 12.023 10.5971 12.023 10.7536 11.931L18.5001 7.373ZM16.0001 4C17.2871 4 18.347 4.9726 18.4849 6.22293L10.5001 10.9199L2.51526 6.22293C2.65312 4.9726 3.71302 4 5.00008 4H16.0001Z"
                fill="#667085"
              />
            </svg>
            service&#64;vertel.com.au
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M7.4873 2.06589C8.39569 1.79208 9.36278 2.19479 9.81442 3.01224L9.88866 3.16075L10.5501 4.63213C10.9627 5.54992 10.7823 6.61908 10.1078 7.3496L9.97554 7.48248L8.93219 8.45541C8.74441 8.63298 8.88537 9.32181 9.56551 10.4999C10.1774 11.5597 10.6751 12.0552 10.9201 12.0823L10.963 12.082L11.016 12.0716L13.0668 11.4446C13.6335 11.2713 14.244 11.4487 14.63 11.8865L14.7217 12.0014L16.0783 13.8815C16.6301 14.6462 16.5692 15.6842 15.9536 16.378L15.8317 16.5039L15.2889 17.018C14.295 17.9592 12.8437 18.2346 11.5741 17.7231C9.63891 16.9433 7.88101 15.1607 6.28393 12.3945C4.68348 9.62242 4.01925 7.20438 4.31622 5.13565C4.50001 3.85539 5.37325 2.78674 6.57773 2.3462L6.77061 2.28192L7.4873 2.06589Z"
                fill="#667085"
              />
            </svg>
            1300 837 835
          </li>
        </ul>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
