/**
 * Api Service and feature
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

import {
  AnyType,
  ApiRes,
  ApiResWithData,
  ICardsResp,
} from '~interfaces/shared.interface';
import { Acl, LoggedIn, User } from '~interfaces/user.interface';
import { UrlService } from '~services/url/url.service';
import { GlobalService } from '~services/global/global.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends UrlService {
  constructor(
    private httpClient: HttpClient,
    private globalService: GlobalService,
  ) {
    super();
  }

  localPing(): Observable<ApiResWithData<boolean>> {
    return this.httpClient
      .get<ApiResWithData<boolean>>(this.localPingURL())
      .pipe(retry(1));
  }

  doLogin(payload: AnyType): Observable<ApiResWithData<LoggedIn>> {
    return this.httpClient
      .post<ApiResWithData<LoggedIn>>(this.loginURL, payload)
      .pipe(
        map((resp) => {
          this.globalService.setAccessToken = resp.data.accessToken;
          this.globalService.setAcl = resp.data.user.accessControlList as Acl;
          delete resp.data.user.accessControlList;
          this.globalService.setUser = resp.data.user;
          return resp;
        }),
      );
  }

  sendForgetPasswordLink(payload: AnyType): Observable<ApiRes> {
    return this.httpClient.patch<ApiRes>(this.forgotPasswordURL, payload);
  }

  verifySessionTokenLink(payload: AnyType): Observable<ApiResWithData<any>> {
    return this.httpClient.post<ApiResWithData<{ status: boolean }>>(
      this.verifySessionTokenURL,
      payload,
    );
  }

  resetPassword(payload: AnyType): Observable<ApiRes> {
    return this.httpClient.put<ApiRes>(this.resetPasswordURL, payload);
  }

  setPassword(payload: AnyType): Observable<ApiRes> {
    return this.httpClient.put<ApiRes>(this.setPasswordURL, payload);
  }

  getAcl(): Observable<ApiResWithData<Acl>> {
    return this.httpClient.get<ApiResWithData<Acl>>(this.aclURL).pipe(retry(1));
  }

  whoiam(): Observable<ApiResWithData<User>> {
    return this.httpClient.get<ApiResWithData<User>>(this.whoiamURL).pipe(
      map((resp) => {
        this.globalService.setAcl = resp.data.accessControlList as Acl;
        delete resp.data.accessControlList;
        this.globalService.setUser = resp.data;
        return resp;
      }),
    );
  }

  getDashboardCards() {
    return this.httpClient.get<ICardsResp>(this.dashboardCardsURL);
  }
}
