import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements AfterViewInit {
  @Output() menuToggled = new EventEmitter<boolean>();
  activeIndex = 0;
  panelOpenState = false;
  disableAnimation = true;
  userTypeId!: string;

  constructor(){
    this.userTypeId = JSON.parse(sessionStorage.getItem('user') as string).userTypeId;
  }
  
  ngAfterViewInit() {
    setTimeout(() => (this.disableAnimation = false));
  }

  // applyAllFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   console.log(filterValue);
  // }
}
