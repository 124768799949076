/**
 * Global State will implemented here.
 */
import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { BehaviorSubject, concat, interval } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import { Alert } from '@vertel/alert';
import { Confirm } from '@vertel/confirm';

import { AnyType } from '~interfaces/shared.interface';
import { Acl, User } from '~interfaces/user.interface';
import { environment } from '~environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public readonly isLoggedIn$ = new BehaviorSubject<boolean>(false);
  public readonly accessToken$ = new BehaviorSubject<string>('');
  public readonly acl$ = new BehaviorSubject<Acl | null>(null);
  public readonly user$ = new BehaviorSubject<User | null>(null);
  constructor(
    private swUpdate: SwUpdate,
    private applicationRef: ApplicationRef,
    private confirm: Confirm,
    private alert: Alert,
  ) {
    if (!environment.development) {
      // check for new version in every 2 hours after application got stable
      const appIsStable$ = this.applicationRef.isStable.pipe(
        first((isStable) => isStable === true),
      );
      const everyTwoHours$ = interval(2 * 60 * 60 * 1000);
      const everyTwoHoursOnceAppIsStable$ = concat(
        appIsStable$,
        everyTwoHours$,
      );
      everyTwoHoursOnceAppIsStable$.subscribe(() => swUpdate.checkForUpdate());

      const updatesAvailable$ = this.swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map((evt) => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        })),
      );

      // if new version is available then update the app.
      updatesAvailable$.subscribe((_) => {
        const confirm$ = this.confirm.present(
          'App update is available. Do you want to update now ?',
        );
        confirm$.subscribe((flag) => {
          if (flag) {
            this.swUpdate
              .activateUpdate()
              .then(() => document.location.reload());
          }
        });
      });

      // if error occurs while updating the app then alert the user.
      this.swUpdate.unrecoverable.subscribe((event) => {
        this.alert.present(
          `An error occurred that we cannot recover from:\n${event.reason}\n\n` +
            'Please reload the page.',
        );
      });
    }
  }

  public set setAccessToken(token: string | null) {
    if (token) {
      sessionStorage.setItem('accessToken', token);
      this.accessToken$.next(token);
      this.isLoggedIn$.next(true);
    } else {
      this.accessToken$.next('');
      this.isLoggedIn$.next(false);
    }
  }

  public get getAccessToken(): string | null {
    return sessionStorage.getItem('accessToken');
  }

  public set setUser(user: User | null) {
    sessionStorage.setItem('user', JSON.stringify(user));
    this.user$.next(user);
  }

  public get getUser(): User | null {
    const userStr = sessionStorage.getItem('user');
    if (userStr) {
      return JSON.parse(userStr);
    }
    return null;
  }

  public set setAcl(acl: Acl | null) {
    sessionStorage.setItem('acl', JSON.stringify(acl));
    this.acl$.next(acl);
  }

  public get getAcl(): Acl | null {
    const aclStr = sessionStorage.getItem('acl');
    if (aclStr) {
      return JSON.parse(aclStr);
    }
    return null;
  }

  public set setContact(contact: AnyType | null) {
    sessionStorage.setItem('contact', JSON.stringify(contact));
  }

  public get getContact(): AnyType | null {
    const contactStr = sessionStorage.getItem('contact');
    if (contactStr) {
      const contact = JSON.parse(contactStr) as AnyType;
      return contact;
    }
    return null;
  }

  public logout(): void {
    localStorage.clear();
    sessionStorage.clear();
    this.isLoggedIn$.next(false);
    this.accessToken$.next('');
    this.acl$.next(null);
    window.location.reload();
  }
}
export const PAGE_SIZE_OPTIONS = [10, 25, 75, 150, 250, 500, 1000];
