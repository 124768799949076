<div class="container" *ngIf="cards">
  <div class="row">
    <div class="col-lg-4">
      <mat-card class="p-3">
        <h4>
          <img src="assets/icon4.svg" alt="" />
          <span class="px-3 pt-1">Services</span>
        </h4>
        <div class="d-flex">
          <span class="num-1 me-4"
            >{{
              cards.services.totalServices < 10
                ? "0" + cards.services.totalServices
                : cards.services.totalServices || 0 || 0
            }}
            <span class="num-text">Services</span>
          </span>
          <span class="num-2 pt-1"
            >{{
              activeServices < 10 ? "0" + activeServices : activeServices || 0
            }}
            <span class="num-text">Active</span>
          </span>
        </div>
      </mat-card>
    </div>
    <div class="col-lg-4">
      <mat-card class="p-3">
        <h4>
          <img src="assets/icon5.svg" alt="" />
          <span class="px-3 pt-1">Open Tickets</span>
        </h4>
        <div class="d-flex justify-content-between">
          <span class="num-1">
            {{ openIncident < 10 ? "0" + openIncident : openIncident || 0 }}
            <span class="num-text">Tickets</span>
          </span>
        </div>
      </mat-card>
    </div>
    <div class="col-lg-4">
      <mat-card class="p-3">
        <h4>
          <img src="assets/icon6.svg" alt="" />
          <span class="px-3 pt-1">Closed Tickets</span>
        </h4>
        <div class="d-flex justify-content-between">
          <span class="num-1">
            {{
              closedIncident < 10
                ? "0" + closedIncident
                : closedIncident || 0 || 0
            }}
            <span class="num-text">Tickets</span>
          </span>
          <span class="num-time pt-2 mt-1">Last 3 months</span>
        </div>
      </mat-card>
    </div>
  </div>
</div>

<div class="container d-none">
  <div class="row">
    <div class="col-lg-3">
      <mat-card class="p-3">
        <h4>
          <img src="assets/icon1.svg" alt="" />
          <span class="px-3 pt-1">Internet Speed</span>
        </h4>
        <div class="d-flex justify-content-between">
          <span class="num-1"
            >120
            <span class="num-text">Mbps</span>
          </span>
          <span class="num-time pt-2 mt-1">2h ago</span>
        </div>
      </mat-card>
    </div>
    <div class="col-lg-3">
      <mat-card class="p-3">
        <h4>
          <img src="assets/icon2.svg" alt="" />
          <span class="px-3 pt-1">Data Usage</span>
        </h4>
        <div class="d-flex justify-content-between">
          <span class="num-1"
            >370
            <span class="num-text">GB</span>
          </span>
          <span class="num-time pt-2 mt-1">This month</span>
        </div>
      </mat-card>
    </div>
    <div class="col-lg-3">
      <mat-card class="p-3">
        <h4>
          <img src="assets/icon3.svg" alt="" />
          <span class="px-3 pt-1">Days Until Renewal</span>
        </h4>
        <div class="d-flex justify-content-between">
          <span class="num-1"
            >15
            <span class="num-text">days left</span>
          </span>
          <!-- <span class="num-time pt-2 mt-1">2h ago</span> -->
        </div>
      </mat-card>
    </div>
    <div class="col-lg-3">
      <mat-card class="p-3">
        <h4>
          <img src="assets/icon4.svg" alt="" />
          <span class="px-3 pt-1">Active Services</span>
        </h4>
        <div class="d-flex justify-content-between">
          <span class="num-1"
            >11
            <span class="num-text">services</span>
          </span>
          <!-- <span class="num-time pt-2 mt-1">2h ago</span> -->
        </div>
      </mat-card>
    </div>
  </div>
</div>
