import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Alert } from '@vertel/alert';
import { Loader } from '@vertel/loader';
import { MasterService } from '../master.service';
import { pluck } from 'rxjs/operators';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.scss'],
})
export class AdduserComponent implements OnInit {
  createUserForm!: FormGroup;
  service: any = [];
  organizationData = [];
  selectedService: string | undefined;
  organizationId!: string;
  searchControl = new FormControl('');
  filteredServiceOptions!: any[];
  keyword!: string;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private fb: FormBuilder,
    private loader: Loader,
    private alert: Alert,
    private router: Router,
    private masterService: MasterService,
  ) {}

  ngOnInit() {
    this.masterService
      .getOrganizations()
      .pipe(pluck('data'))
      .subscribe((res: any) => {
        res.filter((item: any) =>
          this.service.push({
            name: item.name,
            _id: item._id,
          }),
        );
      });
    this.organizationData = this.service;
    this.createUserForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      organization: ['', Validators.required],
    });
  }

  goBack() {
    this.router.navigateByUrl('master?d=true');
  }

  opened = true;
  toggle(): void {
    this.opened = !this.opened;
  }

  hideSidebar() {
    this.breakpointObserver
      .observe(['(max-width: 767px)'])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.opened = false;
        }
      });
  }

  onSubmit(): void {
    if (this.createUserForm.valid) {
      this.service.forEach((item: any) => {
        if (item.name == this.createUserForm?.get('organization')?.value) {
          this.organizationId = item._id;
        }
      });
      const payload: any = {
        firstName: this.createUserForm?.get('firstName')?.value,
        lastName: this.createUserForm?.get('lastName')?.value,
        email: this.createUserForm?.get('email')?.value,
        organizations: this.organizationId,
      };
      this.masterService.createUserAdmin(payload).subscribe(
        (res: any) => {
          this.alert.present(res.message);
          this.router.navigateByUrl('/master?d=true');
        },
        (error) => {
          this.loader.stop();
          this.alert.present(error.error.message);
        },
      );
    }
  }

  filterOptions(event: any) {
    this.loader.start();
    this.service = this.organizationData.filter((option: any) => {
      return option['name']
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
      this.loader.stop();
    });
  }
}
