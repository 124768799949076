import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from '~models/user';
import { GlobalService } from '~services/global/global.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Output() menuToggled = new EventEmitter<boolean>();
  user!: User;
  constructor(
    public dialog: MatDialog,
    private globalService: GlobalService,
  ) {
    this.user = JSON.parse(sessionStorage.getItem('user') as string);
  }

  onLogout() {
    this.globalService.logout();
  }
}
